import silent_survey from '../assets/img/bg_silent_survey.jpg'
import validation_order from '../assets/img/bg_validation_order.jpg'
function SilentSurveyAndVerification() {
   
    return (
        <>
            <section>
            <img src={silent_survey} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
            <section>
            <img src={validation_order} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default SilentSurveyAndVerification;