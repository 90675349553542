import srg_legalitas from '../assets/img/bg_srg_legalitas.jpg'
function SrgLegalitas() {
   
    return (
        <>
            <section>
            <img src={srg_legalitas} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default SrgLegalitas;