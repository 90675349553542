import React from 'react';

function Card(props:any) {
  return (
    <div className={`card${props.clasname?' '+props.clasname:''}`}>
        {props.children}
    </div>
  );
}

export default Card;
