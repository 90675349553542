import React from 'react';

function CardProfile(props: any) {
    return (
        <div className="profile">
            <img src={props.imgProfile} alt="" />
            <div className="detail">
                <h6>{props.nameProfile}</h6>
                <p>{props.jobProfile}</p>
            </div>
        </div>
    );
}

export default CardProfile;
