import ceo_sambutan from '../assets/img/bg_ceo_sambutan.jpg'
function SambutanCeo() {
   
    return (
        <>
            <section>
            <img src={ceo_sambutan} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default SambutanCeo;