// import ceo_image from '../assets/img/gambarCEO-1.jpg'
// function Visimisi(props: any) {
//     return (
//         <div className="visimisi">
//             <section>
//                 <div style={{ padding: "0px 10%" }}>
//                     <div style={{ margin: "50px 0px" }}>
//                         <h1 style={{ fontSize: "30px" }}>Nilai - Nilai Perusahaan</h1>
//                     </div>
//                 </div>
//                 <div className="card-desc">
//                     <img src={ceo_image} style={{ marginRight: "10px", height: "500px",objectFit: "contain" }} />
//                     <div className="point-desc">
//                         <h4 style={{ margin: "0px 0px 15px 0px" }}><b>VISI:</b></h4>
//                         <p>Menjadi mitra terpercaya dalam industri penagihan utang di Indonesia dengan memberikan solusi yang berintegritas, transparan, dan menghargai martabat semua pihak untuk mendukung keberlanjutan bisnis klien kami.</p>
//                         <h4 style={{ margin: " 35px 0px 15px 0px" }}><b>MISI:</b></h4>
//                        <div className="list-visi-misi">
//                        <ol>
//                             <li><b>Pemulihan Asset :</b> Menyediakan layanan penagihan kredit macet yang efisien dan efektif, membantu klien memulihkan aset finansial mereka dengan cara yang profesional dan etis sesuai dengan hukum yang berlaku</li>
//                             <li><b>Meningkatkan Pendapatan :</b> dengan inisiatif, strategy, target, analisa data dan penanganan intensif yang telah di persiapkan dengan baik menjadi sebuah pondasi untuk meningkatkan janji bayar sehingga membantu klien kami untuk mendapatkan kepastian yang ber-impact pada Increase Cashflow serta menjadi pilihan klien kami untuk mengefisiensi operational cost namun dengan hasil yang sama menggunakan <span style={{ color: "red" }}>internal staff.</span></li>
//                             <li><b>Pendekatan Humanis & Membangun Hubungan :</b> Membangun hubungan yang kuat dengan klien dan debitur berdasarkan rasa saling menghormati, komunikasi yang terbuka, dan pemahaman mendalam tentang situasi keuangan masing-masing pihak dan mengupayakan solusi yang mempertimbangkan keadaan finansial para debitur. Pendekatan terhadap sebuah organisasi kenegaraan serta mengikuti kebudayaan setempat menjadi hal yang positif untuk keberlangsungan kedepannya, memudahkan proses penagihan tanpa menimbulkan konflik yang mengakibatkan buruknya citra para pihak.</li>
//                             <li><b>Inovasi dan Adaptasi :</b> Terus menerapkan inovasi dalam metode penagihan dan adaptasi terhadap perubahan regulasi serta dinamika pasar, guna meningkatkan efektivitas dan efisiensi layanan kami. Menggunakan teknologi terkini untuk meningkatkan efisiensi dan efektivitas layanan penagihan, sekaligus memastikan proses yang transparan dan dapat dipertanggung jawabkan</li>
//                             <li><b>Peningkatan Kualitas Layanan :</b> Berkomitmen untuk terus meningkatkan kualitas layanan melalui pelatihan berkelanjutan dan pengembangan kompetensi bagi seluruh anggota tim kami.</li>
//                             <li><b>Pendidikan Finansial :</b> Berkontribusi pada peningkatan literasi finansial dengan memberikan edukasi kepada debitur mengenai manajemen keuangan, sehingga dapat mencegah terjadinya kredit macet di masa depan.</li>
//                             <li><b>Kepatuhan Regulasi :</b> Memastikan kegiatan perusahaan selalu selaras dengan regulasi yang berlaku, menjaga integritas dan transparansi dalam setiap proses penagihan.</li>
//                             <li><b>Kepatuhan dan Etika Profesional :</b> Menjalankan semua operasional perusahaan dengan mematuhi regulasi yang berlaku dan memegang teguh etika profesional, untuk memastikan bahwa setiap tindakan kami mencerminkan standar tertinggi dari kejujuran dan integritas.</li>
//                             <li><b>Tanggung Jawab Sosial :</b> Mengedepankan tanggung jawab sosial dengan menawarkan solusi penyelesaian utang yang berkeadilan, serta mendukung perkembangan ekonomi masyarakat dan keberlanjutan lingkungan, juga kami memberikan edukasi mengenai manajemen keuangan kepada masyarakat berfungsi untuk mengurangi dampak negatif penagihan utang terhadap kehidupan sosial & ekonomi</li>
//                             <li><b>Peningkatan Kualitas SDM :</b> Menginvestasikan waktu dan sumber daya dalam pelatihan dan pengembangan karyawan untuk memastikan bahwa tim kami memiliki keahlian dan sikap yang diperlukan untuk menangani tugas mereka dengan profesionalisme dan empati yang tinggi.</li>
//                             <li><b>Kerjasama dan Kemitraan :</b> Membangun kerjasama strategis yang kuat dengan klien dan mitra bisnis, serta menyediakan solusi yang disesuaikan dengan kebutuhan mereka untuk mencapai hasil yang optimal.</li>
//                             <li><b>Resolusi Konflik :</b> Menyediakan mekanisme resolusi konflik yang efektif dan adil, yang memungkinkan penyelesaian perselisihan secara aman dan menguntungkan bagi kedua belah pihak.</li>
//                         </ol>
//                        </div>
//                         <h4 style={{ margin: "0px 0px 15px 0px" }}><b>Nilai Nilai PT Solusi Recovery Group</b></h4>
//                         <div className="list-nilai-nilai">
//                             <ul >
//                                 <li>Integrity</li>
//                                 <li>Honest &amp; Customer Satisfaction</li>
//                                 <li>Responsibility</li>
//                                 <li>High Quality &amp; Cleanliness</li>
//                                 <li>Best Experience</li>
//                                 <li>Safety</li>
//                                 <li>environmental sustainability</li>
//                                 <li>Diversity</li>
//                                 <li>Improve</li>
//                                 <li>Increase</li>
//                                 <li>Progress</li>
//                                 <li>Profesionalisme &amp; Cooperation</li>
//                                 <li>Inovasion</li>
//                                 <li>Open, Trust &amp; Transparant</li>
//                                 <li>Collaboration</li>
//                                 <li>Empathy</li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// }

// export default Visimisi;
import visimisi from '../assets/img/bg_visimisi.jpg'
function Visimisi() {
   
    return (
        <>
            <section>
            <img src={visimisi} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default Visimisi;