import srgcassie from '../assets/img/bg_srg_cassie.jpg'
function CassieServicce() {
   
    return (
        <>
            <section>
            <img src={srgcassie} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default CassieServicce;