// import banner_career from '../assets/img/bg-career.jpeg'
import about from '../assets/img/about.jpg'
import banner_footer from "../assets/img/bg-banner-footer.jpeg";
import banner_about from "../assets/img/bg-about.png";
function About(props: any) {
    return (
        <div className="About">
            <section>
            <img src={about} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
                {/* <div className='banner-img' style={{ padding: "150px 0px 150px 0px", position: "relative", backgroundImage: `url(${banner_career})`, backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div style={{
                        backgroundColor: '#424267',
                        opacity: '0.45',
                        transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0'
                    }}>

                    </div>
                    <div className="welcome-text">
                        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '16px', marginBottom: '15px' }}>Our story</p>
                        <h2 className="banner-text" style={{
                            fontSize: '45px',
                            fontWeight: '800',
                            lineHeight: '1.3em',
                            color: 'white'
                        }}>
                            Let’s Change How We Manage Business
                        </h2>
                    </div>
                </div> */}
            </section>
            <section>
                <div className="company-values">
                        <img src={banner_about} alt="" />
                        <div style={{padding:"30px"}}>
                            <h1>Company Values <hr style={{width: "15%",height: "2px",backgroundColor: "#7456f1",margin: "20px 0px"}}/></h1>
                            <div style={{display: "flex",flexWrap: "wrap",gap:10}}>
                                <div className="item">
                                    <h3>Reputation and Track Record</h3>
                                    <p>A company with a strong reputation for successfully recovering businesses from financial distress will likely command a higher value.</p>
                                </div>
                                <div className="item">
                                    <h3>Client Base</h3>
                                    <p>The size and quality of the client base will influence the company’s value. A company with a diverse portfolio of clients across different industries may be more valuable.</p>
                                </div>
                                <div className="item">
                                    <h3>Expertise and Team</h3>
                                    <p>The expertise of the team, including turnaround specialists, financial analysts, and legal advisors, contributes significantly to the company’s value.</p>
                                </div>
                                <div className="item">
                                    <h3>Legal and Regulatory Environment</h3>
                                    <p>Compliance with relevant laws and regulations, as well as any legal risks or liabilities, can impact the company’s value.</p>
                                </div>
                            </div>
                        </div>
                </div>        
            </section>
            <section>
                <div style={{ position: "relative",backgroundColor: "#102035",width: "100%",marginTop: "400px"}}>
                    <div style={{
                        backgroundImage: `url(${banner_footer})`, height: "457px", width: "80%", position: "absolute", top: "-250px", left: "0", right: "0", margin: "auto",
                        borderRadius: "20px", color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div style={{ width: "65%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <h1 style={{ marginBottom: "5px" }}>Your Problems are in the right hands</h1>
                            <p style={{ marginBottom: "25px" }}>the value of a business recovery company is a complex interplay of these factors, and a comprehensive evaluation considering all these aspects would be necessary to determine its true worth.</p>
                            <a href='#' className='button' style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px", fontSize: "16px", backgroundColor: "rgb(116, 86, 241)" }}>Reach out now</a>
                        </div>
                    </div>
                    <div style={{ height: "250px" }}>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;