function Page404(props:any) {
  return (
    <div style={{display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",backgroundColor: "white",padding:" 120px 0px"}}>
      <div className="number">404</div>
      <div className="number">Page Not Found</div>
    </div>
  );
}

export default Page404;
