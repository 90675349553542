import organitation from '../assets/img/organitation.jpg'
function Organitation() {
   
    return (
        <>
            <section>
            <img src={organitation} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default Organitation;
