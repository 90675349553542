
function Footer(props: any) {
    return (
        <div className="footer">
            
            <div className="ct-container">
                <div style={{ margin: "", padding: "30px 10%", display: "flex", flexWrap: "wrap" }}>
                    <div style={{ marginRight: "25px" }}>
                        <h3 style={{ color: "white", marginBottom: "15px" }}>Contact Informations</h3>
                        <p style={{ marginBottom: "15px" }}>PT Solusi Recovery Group:</p>
                        <ul style={{ listStyle: "none" }}>
                            <li>
                                <a style={{ display: "flex" }} href="#" >
                                    <div className='iconInfo map'>
                                        <svg width="18" height="18" viewBox="0 0 20 20" aria-hidden="true"><path d="M10,0C6.1,0,3,3.1,3,7c0,4.5,6,11.8,6.2,12.1L10,20l0.8-0.9C11,18.8,17,11.5,17,7C17,3.1,13.9,0,10,0z M10,2c2.8,0,5,2.2,5,5c0,2.7-3.1,7.4-5,9.8C8.1,14.4,5,9.7,5,7C5,4.2,7.2,2,10,2zM10,4.5C8.6,4.5,7.5,5.6,7.5,7S8.6,9.5,10,9.5s2.5-1.1,2.5-2.5S11.4,4.5,10,4.5z"></path></svg>
                                    </div>
                                    <div className="contact-info">
                                        <span className="contact-title">Address:</span>
                                        <span className="contact-text">
                                            Avani Gyoen Residence Blok B1 No3 JL. Arco Raya Kel. Citayam Kec. Tajur Halang Kab. Bogor, Jawa Barat 16320
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a style={{ display: "flex" }} href="https://wa.me/6281818787876" >
                                    <div className='iconInfo wa'>
                                        <svg width="18" height="18" viewBox="0 0 20 20" aria-hidden="true"><path d="M4.4,0C4,0,3.6,0.2,3.2,0.4l0,0l0,0L0.8,2.9l0,0C0,3.6-0.2,4.7,0.1,5.6c0,0,0,0,0,0c0.7,1.9,2.3,5.5,5.6,8.7c3.3,3.3,6.9,4.9,8.7,5.6h0c0.9,0.3,1.9,0.1,2.7-0.5l2.4-2.4c0.6-0.6,0.6-1.7,0-2.4l-3.1-3.1l0,0c-0.6-0.6-1.8-0.6-2.4,0l-1.5,1.5c-0.6-0.3-1.9-1-3.1-2.2C8,9.5,7.4,8.2,7.2,7.6l1.5-1.5c0.6-0.6,0.7-1.7,0-2.4l0,0L8.6,3.6L5.6,0.5l0,0l0,0C5.2,0.2,4.8,0,4.4,0zM4.4,1.5c0.1,0,0.1,0,0.2,0.1l3.1,3.1l0.1,0.1c0,0,0,0.1,0,0.2L5.7,6.9L5.3,7.3l0.2,0.5c0,0,0.9,2.4,2.7,4.1L8.4,12c1.8,1.6,3.9,2.5,3.9,2.5l0.5,0.2l2.3-2.3c0.1-0.1,0.1-0.1,0.2,0l3.1,3.1c0.1,0.1,0.1,0.1,0,0.2l-2.4,2.4c-0.4,0.3-0.7,0.4-1.2,0.2c-1.7-0.7-5.1-2.2-8.1-5.2c-3-3-4.6-6.5-5.2-8.2c-0.1-0.3,0-0.8,0.2-1l0,0l2.3-2.4C4.2,1.6,4.3,1.5,4.4,1.5z"></path></svg>
                                    </div>
                                    <div className="contact-info">
                                        <span className="contact-title">Whatsapp:</span>
                                        <span className="contact-text">
                                            +6281818787876
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a style={{ display: "flex" }} href="tel:081818787876" >
                                    <div className='iconInfo phone'>
                                        <svg width="18" height="18" viewBox="0 0 20 20" aria-hidden="true"><path d="M13.5,20H6.5c-1.6,0-2.9-1.3-2.9-2.9V2.9C3.5,1.3,4.8,0,6.5,0h7.1c1.6,0,2.9,1.3,2.9,2.9v14.1C16.5,18.7,15.2,20,13.5,20zM6.7,1.7C5.8,1.7,5,2.5,5,3.4v13.2c0,0.9,0.7,1.7,1.7,1.7h6.6c0.9,0,1.7-0.7,1.7-1.7V3.4c0-0.9-0.7-1.7-1.7-1.7H6.7z"></path><path d="M11.2,4.4H8.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h2.4c0.3,0,0.6,0.3,0.6,0.6S11.5,4.4,11.2,4.4z"></path><circle cx="10" cy="15.7" r="1.2"></circle></svg>
                                    </div>
                                    <div className="contact-info">
                                        <span className="contact-title">Mobile:</span>
                                        <span className="contact-text">
                                            +6281818787876
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="ftag-etc">
                        <h3 style={{ color: "white", marginBottom: "15px" }}>A</h3>
                        <a href="#" >About Organization</a>
                        <a href="#" >Our Clients</a>
                        <a href="#" >Our Partners</a>
                    </div>
                </div>
            </div>
            <div className="cr-media">
                <div className="cr-cc" style={{ color: "#4d5d6d" }}>Copyright © 2024 - SRG Group Company</div>
                <div className="ct-social-box" data-color="custom" data-icon-size="custom" data-icons-type="simple">

                    <a href="#" data-network="facebook" aria-label="Facebook" rel="noopener">
                        <span className="ct-icon-container">
                            <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M20,10.1c0-5.5-4.5-10-10-10S0,4.5,0,10.1c0,5,3.7,9.1,8.4,9.9v-7H5.9v-2.9h2.5V7.9C8.4,5.4,9.9,4,12.2,4c1.1,0,2.2,0.2,2.2,0.2v2.5h-1.3c-1.2,0-1.6,0.8-1.6,1.6v1.9h2.8L13.9,13h-2.3v7C16.3,19.2,20,15.1,20,10.1z"></path>
                            </svg>
                        </span>
                    </a>

                    <a href="#" data-network="twitter" aria-label="X (Twitter)" rel="noopener">
                        <span className="ct-icon-container">
                            <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M2.9 0C1.3 0 0 1.3 0 2.9v14.3C0 18.7 1.3 20 2.9 20h14.3c1.6 0 2.9-1.3 2.9-2.9V2.9C20 1.3 18.7 0 17.1 0H2.9zm13.2 3.8L11.5 9l5.5 7.2h-4.3l-3.3-4.4-3.8 4.4H3.4l5-5.7-5.3-6.7h4.4l3 4 3.5-4h2.1zM14.4 15 6.8 5H5.6l7.7 10h1.1z"></path>
                            </svg>
                        </span>
                    </a>

                    <a href="#" data-network="instagram" aria-label="Instagram" rel="noopener">
                        <span className="ct-icon-container">
                            <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
                                <circle cx="10" cy="10" r="3.3"></circle>
                                <path d="M14.2,0H5.8C2.6,0,0,2.6,0,5.8v8.3C0,17.4,2.6,20,5.8,20h8.3c3.2,0,5.8-2.6,5.8-5.8V5.8C20,2.6,17.4,0,14.2,0zM10,15c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S12.8,15,10,15z M15.8,5C15.4,5,15,4.6,15,4.2s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S16.3,5,15.8,5z"></path>
                            </svg>
                        </span>
                    </a>

                    <a href="#" data-network="dribbble" aria-label="Dribbble" rel="noopener">
                        <span className="ct-icon-container">
                            <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0 M16.1,5.2c1,1.2,1.6,2.8,1.7,4.4c-1.1-0.2-2.2-0.4-3.2-0.4v0h0c-0.8,0-1.6,0.1-2.3,0.2c-0.2-0.4-0.3-0.8-0.5-1.2C13.4,7.6,14.9,6.6,16.1,5.2 M10,2.2c1.8,0,3.5,0.6,4.9,1.7c-1,1.2-2.4,2.1-3.8,2.7c-1-2-2-3.4-2.7-4.3C8.9,2.3,9.4,2.2,10,2.2 M6.6,3c0.5,0.6,1.6,2,2.8,4.2C7,8,4.6,8.1,3.2,8.1c0,0-0.1,0-0.1,0h0c-0.2,0-0.4,0-0.6,0C3,5.9,4.5,4,6.6,3 M2.2,10c0,0,0-0.1,0-0.1c0.2,0,0.5,0,0.9,0h0c1.6,0,4.3-0.1,7.1-1c0.2,0.3,0.3,0.7,0.4,1c-1.9,0.6-3.3,1.6-4.4,2.6c-1,0.9-1.7,1.9-2.2,2.5C2.9,13.7,2.2,11.9,2.2,10 M10,17.8c-1.7,0-3.3-0.6-4.6-1.5c0.3-0.5,0.9-1.3,1.8-2.2c1-0.9,2.3-1.9,4.1-2.5c0.6,1.7,1.1,3.6,1.5,5.7C11.9,17.6,11,17.8,10,17.8M14.4,16.4c-0.4-1.9-0.9-3.7-1.4-5.2c0.5-0.1,1-0.1,1.6-0.1h0h0h0c0.9,0,2,0.1,3.1,0.4C17.3,13.5,16.1,15.3,14.4,16.4"></path>
                            </svg>
                        </span>
                    </a>

                    <a href="#" data-network="slack" aria-label="Slack" rel="noopener">
                        <span className="ct-icon-container">
                            <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M7.4,0C6.2,0,5.2,1,5.2,2.2s1,2.2,2.2,2.2h2.2V2.2C9.6,1,8.6,0,7.4,0zM12.6,0c-1.2,0-2.2,1-2.2,2.2v5.2c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2V2.2C14.8,1,13.8,0,12.6,0z M2.2,5.2C1,5.2,0,6.2,0,7.4s1,2.2,2.2,2.2h5.2c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2H2.2zM17.8,5.2c-1.2,0-2.2,1-2.2,2.2v2.2h2.2c1.2,0,2.2-1,2.2-2.2S19,5.2,17.8,5.2z M2.2,10.4c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2v-2.2H2.2zM7.4,10.4c-1.2,0-2.2,1-2.2,2.2v5.2c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2v-5.2C9.6,11.4,8.6,10.4,7.4,10.4z M12.6,10.4c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2h5.2c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2H12.6zM10.4,15.7v2.2c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2H10.4z"></path>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div >
    );
}

export default Footer;
