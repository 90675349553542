import silent_survey from '../assets/img/bg_silent_survey.jpg'
function SilentSurvey() {
   
    return (
        <>
            <section>
            <img src={silent_survey} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default SilentSurvey;