import srg_experience from '../assets/img/bg_srg_experience.jpg'
function SrgExperience() {
   
    return (
        <>
            <section>
            <img src={srg_experience} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default SrgExperience;