import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './view/Home';
import BtnWa from './components/BtnWa';
import Footer from './components/Footer';
import Page404 from './view/Page404';
import About from './view/About';
import Visimisi from './view/Visimisi';
import Sambutan from './view/Sambutan';
import Career from './view/Career';
import Contact from './view/Contact';
import Organitation from './view/Organitation';
import LegalServicce from './view/LegalServicce';
import CollectionAndRecovery from './view/CollectionAndRecovery';
import CassieServicce from './view/CassieServicce';
import PenagihanWorkflow from './view/PenagihanWorkflow';
import ValidationOrder from './view/ValidationOrder';
import SrgLegalitas from './view/SrgLegalitas';
import SrgExperience from './view/SrgExperience';
import SilentSurvey from './view/SilentSurvey';
import SambutanCeo from './view/SambutanCeo';
import SambutanCofounder from './view/SambutanCofounder';
import SilentSurveyAndVerification from './view/SilentSurveyAndVerification';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <BrowserRouter>
          <Routes>
            <Route>
              <Route path="/" element={<Home />} />
              <Route path="/struktur_organisasi_holding" element={<Organitation />} />
              <Route path="/struktur_organisasi_group" element={<Organitation />} />
              <Route path="/sejarah_singkat_srg" element={<About />} />
              <Route path="/about" element={<About />} />
              <Route path="/legal_servicce" element={<LegalServicce />} />
              <Route path="/cassie_servicce" element={<CassieServicce />} />
              <Route path="/penagihan_workflow" element={<PenagihanWorkflow />} />
              <Route path="/validation_order" element={<ValidationOrder />} />
              <Route path="/srg_legalitas" element={<SrgLegalitas />} />
              <Route path="/srg_experience" element={<SrgExperience />} />
              <Route path="/silent_survey" element={<SilentSurvey />} />
              <Route path="/silent_survey_and_validation_order" element={<SilentSurveyAndVerification />} />
              <Route path="/collection_recovery" element={<CollectionAndRecovery />} />
              <Route path="/nilai-nilai_perusahaan" element={<Visimisi />} />
              <Route path="/sambutan_cofounder_direktur" element={<Sambutan />} />
              <Route path="/sambutan_ceo" element={<SambutanCeo />} />
              <Route path="/sambutan_cofounder" element={<SambutanCofounder />} />
              <Route path="/srg-career" element={<Career />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
      <BtnWa />
    </div>
  );
}

export default App;
