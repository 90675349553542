export default function setTitle(title:string){
    document.title = title;
}

export  function toggleClass(e:string,classToggle:string){
        let el = document.querySelector(e);
        if (el?.classList.contains(classToggle)) {
          el?.classList.remove(classToggle);
        } else {
          el?.classList.add(classToggle);
        }
}