import banner_career from '../assets/img/bg-career.jpeg'
function Career(props: any) {
    return (
        <div className='banner-img' style={{ padding: "150px 0px 250px 0px", position: "relative", backgroundImage: `url(${banner_career})`,  backgroundSize: "contain" }}>
        <div style={{
            backgroundColor: '#424267',
            opacity: '0.45',
            transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0'
        }}>

        </div>
        <div className="welcome-text">
           
        </div>
    </div>
    );
}

export default Career;