import collectionandrecovery from '../assets/img/bg_collection_recovery.jpg'
function CollectionAndRecovery() {
   
    return (
        <>
            <section>
            <img src={collectionandrecovery} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default CollectionAndRecovery;