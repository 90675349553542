import legalservicce from '../assets/img/bg_legal_service.jpg'
import legalitas from '../assets/img/bg_srg_legalitas.jpg'
function LegalServicce() {
   
    return (
        <>
            <section>
            <img src={legalservicce} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
            <section>
            <img src={legalitas} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default LegalServicce;