import banner_contact from '../assets/img/bg-contact.jpeg';
import bg_contact from '../assets/img/bg_contact.jpg';
import Card from '../components/Card';
function Contact(props: any) {
    return (
        <div className="contacts">
            <section>
                <div className='banner-img' style={{ padding: "150px 0px 250px 0px", position: "relative", backgroundImage: `url(${banner_contact})`, backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div style={{
                        backgroundColor: '#424267',
                        opacity: '0.45',
                        transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0'
                    }}>

                    </div>
                    <div className="welcome-text">
                        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '16px', marginBottom: '15px' }}>Wanna Chat?</p>
                        <h2 className="banner-text" style={{
                            fontSize: '45px',
                            fontWeight: '800',
                            lineHeight: '1.3em',
                            color: 'white'
                        }}>
                            Send Us Your Feedback, We Love Hearing It!
                        </h2>
                    </div>
                </div>
            </section>
            <section>
                <div style={{ padding: "100px 10%" }}>
                    <div className='inf-contact' style={{ borderRadius: "15px", overflow: "hidden", height: "350px", padding: "0px 50px", position: "relative", backgroundImage: `url(${bg_contact})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", display: "flex", alignItems: "end" }}>
                        <div style={{ backgroundColor: "#61CE70", opacity: "0.64", transition: "background 0.3s ease 0s, border-radius 0.3s ease 0s, opacity 0.3s ease 0s", position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px" }}></div>
                        <p style={{ position: "relative", marginBottom: "35px", color: "white" }}><strong>Bogor, Indonesia</strong><br />Avani Gyoen Residence Blok B1 No. 3<br />contactus@srgroup.co.id</p>
                    </div>
                    <div className="card-info">
                        <Card>
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-map-marked-alt" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"></path></svg>
                            <h3>Physical Address​</h3>
                            <p>Avani Gyoen Residence Blok B1 No. 3 Jl. Arco Raya Kel. Citayam Kec. Tajur Halang Kab. Bogor, Jawa Barat 16320</p>
                        </Card>
                        <Card>
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-clock" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg>
                            <h3>Work Hours</h3>
                            <p>Monday to Friday: 10am – 20pm</p>
                        </Card>
                        <Card>
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-envelope" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                            <h3>Email Address</h3>
                            <p>contactus@srgroup.co.id</p>
                        </Card>
                        <Card>
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-mobile" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path></svg>
                            <h3>Phone Numbers</h3>
                            <p>+6281818787876</p>
                        </Card>
                    </div>
                </div>
            </section>
            <section>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: "column" }}>
                        <div className="contact-form">
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',marginBottom:"65px" }}>
                                <h1 style={{ color: '#61CE70', fontWeight: "800", fontSize: "35px" }}>Contact Us</h1>
                                <p style={{ textAlign: 'center', marginTop: '15px' }}>Help me to know our business issue, free to ask everyday.</p>
                            </div>
                            <div className="frm" style={{ display: "flex", padding: "0px 10%" }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d507430.20120077103!2d106.827953!3d-6.483837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e8474cc4072f%3A0x8562303255f11c55!2sCitayam%2C%20Tajur%20Halang%2C%20Bogor%20Regency%2C%20West%20Java%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1717638626950!5m2!1sen!2sus" width="700" height="450" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            <div style={{display: "flex",flex:1}}>
                                <form action="">
                                    <div className="frm-inp">
                                        <label>Full Name <span style={{ color: "red" }}>*</span></label>
                                        <input type="text" required />
                                    </div>
                                    <div className="frm-inp">
                                        <label>Email Address <span style={{ color: "red" }}>*</span></label>
                                        <input type="email" required />
                                    </div>
                                    <div className="frm-inp">
                                        <label>Subject</label>
                                        <input type="text" />
                                    </div>
                                    <div className="frm-inp">
                                        <label>Comment or Message <span style={{ color: "red" }}>*</span></label>
                                        <textarea name="" id="" rows={6} required></textarea>
                                    </div>
                                    <button type={"submit"} className="button">Send Message</button>
                                </form>
                            </div>
                        </div>
                        </div>
                        
                    </div>
            </section>
        </div>
    );
}

export default Contact;