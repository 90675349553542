import penagihan_workflow from '../assets/img/bg_penagihan_workflow.jpg'
function PenagihanWorkflow() {
   
    return (
        <>
            <section>
            <img src={penagihan_workflow} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default PenagihanWorkflow;