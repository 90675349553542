import cofounder_sambutan from '../assets/img/bg_cofounder_sambutan.jpg.jpg'
function SambutanCofounder() {
   
    return (
        <>
            <section>
            <img src={cofounder_sambutan} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default SambutanCofounder;