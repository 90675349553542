import ceo_image from '../assets/img/gambarCEO-1.jpg'
import ceof_image from '../assets/img/gambarCOF.jpg'
function Sambutan(props: any) {
    return (
        <div className="sambutan">
            <section>
            <div className="card-desc">
                    <img src={ceof_image} style={{ marginRight: "10px", height: "500px", objectFit: "contain" }} />
                    <div className="point-desc">
                        <h4 style={{ margin: "0px 0px 15px 0px" }}><b>Selamat datang</b></h4>
                        <p style={{ marginBottom: "30px" }}>Seiring langkah kami dalam merintis perjalanan
                            yang penuh tantangan namun penuh harapan,
                            saya sebagai salah satu Co-Founder dari PT.
                            Solusi Recovery Group (SRG) dengan bangga
                            menyambut Anda dalam dunia inovasi dan
                            keberanian yang kami ciptakan. Tentunya
                            dalam setiap tantangan terutama dalam
                            sektor bisnis bukan lah suatu hal yang mudah
                            ditengah berkembangnya enterpreneur namun
                            semangat kami tetap sama dan tetap optimis
                            untuk memberikan pelayanan terbaik kami
                            kepada Mitra, Client, Calon Customer, Customer
                            dan Vendor.</p>
                        <p style={{ marginBottom: "30px" }}>PT. Solusi Recovery berdiri didorong oleh visi &
                            misi dengan harapan tidak hanya mencapai
                            kesuksesan dalam bisnis, yang terpenting
                            adalah bagaimana kami dapat memberikan
                            dampak positif bagi lingkungan sekitar agar
                            maju bersama sehingga menjadi sebuah
                            energy yang positive bagi SRG dan Client kami,
                            karena PT. Solusi Recovery Group berfokus pada
                            sektor Jasa. Sehingga kami mendapatkan
                            kepercayaan penuh untuk membantu
                            meningkatkan cashflow tentunya dengan cara,
                            strategy, initiative yang sesuai dengan bidang
                            jasa yang dibutuhkan. Serta memegang teguh
                            Nilai – Nilai yang kami bangun agar tidak keluar
                            dari koridor yang sudah kami bangun.</p>
                        <p style={{ marginBottom: "30px" }}>Kami tahu dalam bisnis ini yang paling utama
                            dan harus dijaga adalah kepercayaan. Kami
                            sangat menjaga kepercayaan anda kepada
                            PT. Solusi Recovery Group
                            Terima kasih atas dukungan Anda dalam
                            perjalanan ini. Bersama, mari kita wujudkan
                            mimpi-mimpi besar dan ciptakan cerita sukses
                            yang tak terlupakan.</p>
                        <p style={{ marginBottom: "30px" }}>Pantang pulang sebelum menang.</p>
                        <p style={{ marginBottom: "30px" }}>Ruby Rinaldy</p>
                        <p style={{ marginBottom: "30px" }}>Co-Founder & Board of Commisioner</p>
                        <p style={{ marginBottom: "30px" }}>PT. Solusi Recovery Group</p>
                    </div>
                </div>
                <div className="card-desc">
                    <img src={ceo_image} style={{ marginRight: "10px", height: "500px", objectFit: "contain" }} />
                    <div className="point-desc">
                        <h4 style={{ margin: "0px 0px 15px 0px" }}><b>Selamat datang di PT. Solusi Recovery Group!</b></h4>
                        <p style={{ marginBottom: "30px" }}>Saya Eliza Humaira Saragi sebagai CEO & President
                            Director di PT. Solusi Recovery Group (SRG), dengan
                            senang hati menyambut Anda dalam perjalanan kami
                            menuju kesuksesan. Di SRG, kami berkomitmen untuk
                            memberikan layanan terbaik, inovasi yang berkelanjutan,
                            dan nilai tambah bagi pelanggan, mitra, dan seluruh
                            stakeholder kami.</p>
                        <p style={{ marginBottom: "30px" }}>Sejak awal berdirinya SRG, telah menempatkan fokus
                            pada kualitas, integritas, dan keunggulan dalam setiap
                            aspek bisnis kami. Kami terus berusaha untuk menjadi
                            yang terbaik dalam industri ini, tidak hanya dalam hal
                            produk dan layanan, tetapi juga dalam membentuk
                            hubungan yang kuat dengan seluruh orang yang terlibat
                            dengan perusahaan kami.</p>
                        <p style={{ marginBottom: "30px" }}>Kami percaya bahwa kesuksesan perusahaan adalah
                            hasil dari kerja keras, dedikasi, dan kolaborasi tim yang
                            luar biasa. Bersama-sama, kita akan mencapai tujuan
                            besar dan membawa PT. Solusi Recovery Group (SRG) ke
                            puncak kesuksesan.</p>
                        <p style={{ marginBottom: "30px" }}>Terima kasih atas dukungan dan kepercayaan Anda
                            kepada SRG. Mari bersama-sama menciptakan masa
                            depan yang gemilang!</p>
                        <p style={{ marginBottom: "30px" }}>Salam sukses,</p>
                        <p style={{ marginBottom: "30px" }}>Eliza Humaira Saragi</p>
                        <p style={{ marginBottom: "30px" }}>CEO & President Director</p>
                        <p style={{ marginBottom: "30px" }}>PT. Solusi Recovery Group</p>
                    </div>
                </div>
               
            </section>
        </div>
    );
}

export default Sambutan;