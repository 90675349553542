import React, { useEffect, useState } from 'react';
import logo from '../assets/img/logo-d.png';
import logob from '../assets/img/logo-b.png'

function Navbar(props: any) {
  const [stickyClass, setStickyClass] = useState('default' as string);
  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0 ? setStickyClass('sticky') : setStickyClass('default');
    }
  };

  const mobile_menu = (e:string) => {
    var el = document.querySelector(e);
    if (el?.classList.contains('active')) {
      el?.classList.remove('active');
    } else {
      el?.classList.add('active');
    }
  }

  return (
    <div className={`navbar ${stickyClass}`}>
      <div className='menu-mobile'>
        <div style={{ background: "#13161a", position: "absolute", bottom: "0", right: "0", width: "80%", borderRadius: "10px", padding: "15px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <a href='#' onClick={(e)=>mobile_menu('.menu-mobile')} style={{ color: "white", fontSize: "25px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="m4.3 2.9l12.8 12.8l-1.4 1.4L2.9 4.3z" /><path fill="currentColor" d="M17.1 4.3L4.3 17.1l-1.4-1.4L15.7 2.9z" /></svg>
            </a>
          </div>
          <div>
            <ul className='nav-menu-mobile'>
              <li className='mn-about'><a href="#" onClick={(e)=>mobile_menu('.mn-about')}><b>About SRG</b> <svg  width="15" height="15" viewBox="0 0 15 15" style={{fill: "white", marginLeft: "5px"}}><path d="M2.1,3.2l5.4,5.4l5.4-5.4L15,4.3l-7.5,7.5L0,4.3L2.1,3.2z"></path></svg></a> 
                <ul>
                  <li><a href="/sambutan_cofounder">Sambutan CoFounder</a></li>
                  <li><a href="/sambutan_ceo">Sambutan Direktur</a></li>
                  <li><a href="/sejarah_singkat_srg">Sejarah Singkat SRG</a></li>
                  <li><a href="/struktur_organisasi_holding">Struktur Organisasi Holding</a></li>
                  <li><a href="/struktur_organisasi_group">Struktur Organisasi Group</a></li>
                  <li><a href="/nilai-nilai_perusahaan">Nilai - Nilai Perusahaan</a> </li>
                </ul>
              </li>
              <li className='mn-career'><a href="#" onClick={(e)=>mobile_menu('.mn-career')}><b>SRG Career</b></a><svg  width="15" height="15" viewBox="0 0 15 15" style={{fill: "white", marginLeft: "5px"}}><path d="M2.1,3.2l5.4,5.4l5.4-5.4L15,4.3l-7.5,7.5L0,4.3L2.1,3.2z"></path></svg>
                <ul>
                  <li><a href="/job_vacancy">Job Vacancy</a> </li>
                </ul>
              </li>
              <li><a href="/contact"><b>Contact</b></a> </li>
            </ul>
          </div>
          <div style={{ color: "white" }}>
            <p style={{ marginBottom: "10px" }}><strong>Address<br /></strong>Avani Gyoen Residence Blok B1 No3<br />JL. Arco Raya Kel. Citayam Kec. Tajur Halang<br />Kab. Bogor, Jawa Barat 16320</p>
            <p style={{ marginBottom: "10px" }}><strong>Work Hours<br /></strong>Monday to Friday: 9AM - 9PM<strong><br /></strong></p>
          </div>
          <div style={{ marginTop: "20px" }} className="ct-social-box" data-color="custom" data-icon-size="custom" data-icons-type="simple">
            <a style={{ fill: "white" }} href="#" data-network="facebook" aria-label="Facebook" rel="noopener">
              <span className="ct-icon-container">
                <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M20,10.1c0-5.5-4.5-10-10-10S0,4.5,0,10.1c0,5,3.7,9.1,8.4,9.9v-7H5.9v-2.9h2.5V7.9C8.4,5.4,9.9,4,12.2,4c1.1,0,2.2,0.2,2.2,0.2v2.5h-1.3c-1.2,0-1.6,0.8-1.6,1.6v1.9h2.8L13.9,13h-2.3v7C16.3,19.2,20,15.1,20,10.1z"></path>
                </svg>
              </span>
            </a>
            <a style={{ fill: "white" }} href="#" data-network="twitter" aria-label="X (Twitter)" rel="noopener">
              <span className="ct-icon-container">
                <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M2.9 0C1.3 0 0 1.3 0 2.9v14.3C0 18.7 1.3 20 2.9 20h14.3c1.6 0 2.9-1.3 2.9-2.9V2.9C20 1.3 18.7 0 17.1 0H2.9zm13.2 3.8L11.5 9l5.5 7.2h-4.3l-3.3-4.4-3.8 4.4H3.4l5-5.7-5.3-6.7h4.4l3 4 3.5-4h2.1zM14.4 15 6.8 5H5.6l7.7 10h1.1z"></path>
                </svg>
              </span>
            </a>
            <a style={{ fill: "white" }} href="#" data-network="instagram" aria-label="Instagram" rel="noopener">
              <span className="ct-icon-container">
                <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
                  <circle cx="10" cy="10" r="3.3"></circle>
                  <path d="M14.2,0H5.8C2.6,0,0,2.6,0,5.8v8.3C0,17.4,2.6,20,5.8,20h8.3c3.2,0,5.8-2.6,5.8-5.8V5.8C20,2.6,17.4,0,14.2,0zM10,15c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S12.8,15,10,15z M15.8,5C15.4,5,15,4.6,15,4.2s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S16.3,5,15.8,5z"></path>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="brand">
        <div className="logo">
          <a href="/" rel="home"><img loading="lazy" src={stickyClass == "default" ? logob : logo} alt="logo PT. Solusi Recovery Group" /></a>
        </div>
        <div className="tagname">
          <a href="/" rel="home">Your Problems are in the right hands</a>
        </div>
      </div>
      <div className="rbar">
        <ul className="menu">
          <li style={{ position: "relative" }}>
            <a href="/about">ABOUT SRG <svg className="corner" style={{ fill: "white", marginLeft: "5px" }} width="8" height="8" viewBox="0 0 15 15"><path d="M2.1,3.2l5.4,5.4l5.4-5.4L15,4.3l-7.5,7.5L0,4.3L2.1,3.2z"></path></svg></a>
            <div className="submenu">
              <ul className="pmenu">
              <li><a href="/sambutan_cofounder">Sambutan CoFounder</a></li>
              <li><a href="/sambutan_ceo">Sambutan Direktur</a></li>
                {/* <li><a href="/sambutan_cofounder_direktur">Sambutan CoFounder & Direktur</a></li> */}
                <li><a href="/sejarah_singkat_srg">Sejarah Singkat SRG</a></li>
                <li><a href="/struktur_organisasi_holding">Struktur Organisasi Holding</a></li>
                <li><a href="/struktur_organisasi_group">Struktur Organisasi Group</a></li>
                <li><a href="/nilai-nilai_perusahaan">Nilai - Nilai Perusahaan</a> </li>
              </ul>
            </div>
          </li>
          <li ><a href="/srg-career">SRG CAREER <svg className="corner" style={{ fill: "white", marginLeft: "5px" }} width="8" height="8" viewBox="0 0 15 15"><path d="M2.1,3.2l5.4,5.4l5.4-5.4L15,4.3l-7.5,7.5L0,4.3L2.1,3.2z"></path></svg></a>
            <div className="submenu">
              <ul className="pmenu">
                <li><a href="/job_vacancy">Job Vacancy</a> </li>
              </ul>
            </div>
          </li>
          <li><a href="/contact">CONTACT</a></li>
        </ul>
        <a href="#" className='btn-src'>  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><path fill="currentColor" d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"/></svg>
        </a>
        {/* <a href='tel:081818787876' className='button callus'>Call Us</a> */}
      </div>
      <a href='#' className="icn-menu" onClick={(e)=>mobile_menu('.menu-mobile')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M4 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1" /></svg>
      </a>
    </div>
  );
}

export default Navbar;
