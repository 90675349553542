import React, { useEffect, useState } from 'react';
import setTitle, { toggleClass } from '../hooks/helpers';
import banner from '../assets/img/bg-banner.jpeg';
import Card from '../components/Card';
import CardProfile from '../components/CardProfile';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import banner_footer from "../assets/img/bg-banner-footer.jpeg";
import av1 from "../assets/img/review/avatar1.png";
import av2 from "../assets/img/review/avatar2.png";
import av3 from "../assets/img/review/avatar3.png";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



function Home() {
    const [state_modal, setState_modal] = useState("");
    useEffect(() => {
        setTitle('SrgGroup – Solusi Recovery Group')
    }, []);

    function content_modal(text: string) {
        let template;
        switch (text) {
            case "chemical":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h3 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG BINATU, CHEMICAL & PLASTIC</h3>
                        </div>
                        <div className="cbody">
                            <h1>CHEMICAL</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI & KELEBIHAN SRG</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> KEMITRAAN + FEE + PACKAGE YANG DIDAPATKAN</h3> </li>
                                <li> <h3> CHECKOUT PAYMENT</h3></li>
                                <li> <h3> LOCATION</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "autocare":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h1 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG AUTOCARE</h1>
                        </div>
                        <div className="cbody">
                            <h1>AUTOCARE</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI & KELEBIHAN SRG</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> KEMITRAAN + FEE + PACKAGE YANG DIDAPATKAN</h3> </li>
                                <li> <h3> CHECKOUT PAYMENT</h3></li>
                                <li> <h3> LOCATION</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "great_tasty":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h1 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG GREAT TASTY</h1>
                        </div>
                        <div className="cbody">
                            <h1>F&B</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI & KELEBIHAN SRG</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> KEMITRAAN + FEE + PACKAGE YANG DIDAPATKAN</h3> </li>
                                <li> <h3> CHECKOUT PAYMENT</h3></li>
                                <li> <h3> LOCATION</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "hospitality":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h1 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG HOSPITALITY</h1>
                        </div>
                        <div className="cbody">
                            <h1>HOSPITALITY</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI & KELEBIHAN</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> VILLA (BISA BOOKING ONLINE) FASILITAS</h3> </li>
                                <li> <h3> HOTEL (BISA BOOKING ONLINE) FASILITAS</h3></li>
                                <li> <h3> KOST (BISA BOOKING ONLINE) FASILITAS</h3></li>
                                <li> <h3> MEETING ROOM (BISA BOOKING ONLINE) + FASILITAS</h3></li>
                                <li> <h3> BALLROOM</h3></li>
                                <li> <h3> OFFER & PACKAGE</h3></li>
                                <li> <h3> LOCATION</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "verification":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h3 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG SILENT SURVEY & VERIFICATION</h3>
                        </div>
                        <div className="cbody">
                            <h1>SILENT SURVEY & VERIFICATION</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI & KELEBIHAN SRG</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> PRODAK & LAYANAN(KUALITAS MITRA & HASILSURVEY/VERIF)</h3> </li>
                                <li> <h3> HOW TO APPROACH & OPSI</h3></li>
                                <li> <h3> FEE</h3></li>
                                <li> <h3> OUR CLIENT</h3></li>
                                <li> <h3> VISIT NEWSPAPER</h3></li>
                                <li> <h3> COVERAGE AREA HANDLE</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "recovery":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h2 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG COLLECTION & RECOVERY</h2>
                        </div>
                        <div className="cbody">
                            <h1>Field Collection & Desk Collection</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI & KELEBIHAN SRG + SERTIFIKASI</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> PRODAK & LAYANAN</h3></li>
                                <li> <h3> HOW TO APPROACH & OPSI</h3></li>
                                <li> <h3> FEE</h3></li>
                                <li> <h3> OUR CLIENT</h3></li>
                                <li> <h3> VISIT NEWSPAPER</h3></li>
                                <li> <h3> COVERAGE AREA HANDLE</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "legal":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h1 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG X LEGAL</h1>
                        </div>
                        <div className="cbody">
                            <h1>Legal</h1>
                            <ul style={{ paddingLeft: "10px" }}>
                                <li> <h3> DEFINISI</h3></li>
                                <li> <a href={"/nilai_perusahaan"} style={{color:'blue'}}><h3> VISI & MISI</h3></a></li>
                                <li> <h3> PRODAK & LAYANAN</h3></li>
                                <li> <h3> FEE</h3></li>
                                <li> <h3> OUR CLIENT</h3></li>
                                <li> <h3> CLIENTTESTIMONI</h3></li>
                            </ul>
                        </div>
                    </>
                );
                break;
            case "cessie":
                template = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", backgroundColor: "#1c1c43", padding: "15px" }}>
                            <h1 style={{ transform: "rotate(-90deg)", textWrap: "nowrap", width: "75px", marginBottom: "30px", color: "white" }}>SRG CASSIE</h1>
                        </div>
                        <div className="cbody">
                            <h1>CASSIE</h1>
                            <h3>DEFINISI</h3>
                            <p>Definisi Cassie adalah ‘PengalihanHakTagih’ digunakanoleh Perusahaan Pembiayaan untuk mengalihkanhak tagih kepadapihak lain atau pihak ketiga. Proses Cassie tidak menghapus hutang piutang atau tagihan pihak pertama</p>
                            <h3>PROSESCESSIE</h3>
                            <p>Hubungi Bussiness & Marketing Representative Manager kami Novisar Fauzi : 082154120707</p>
                        </div>
                    </>
                );
                break;
        }
        return template;
    }
    return (
        <>
            <div className="cModal">
                <div className='wrpmdl' onClick={() => { toggleClass(".cModal", "show"); }}></div>
                <a href="#" onClick={() => { toggleClass(".cModal", "show"); }} style={{color: "black", fontSize: "25px",position: "absolute",right: "0",top: "0",zIndex: "2",padding: "10px"}}><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="m4.3 2.9l12.8 12.8l-1.4 1.4L2.9 4.3z"></path><path fill="currentColor" d="M17.1 4.3L4.3 17.1l-1.4-1.4L15.7 2.9z"></path></svg></a>
                <div style={{ display: "flex", flexDirection: "row",  minHeight: "50vh", position: "relative" }}>
                    {content_modal(state_modal)}
                </div>
            </div>
            <section>
                <div className='banner-img' style={{ padding: "100px 0px 250px 0px", position: "relative", backgroundImage: `url(${banner})`, backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div style={{
                        backgroundColor: '#424267',
                        opacity: '0.45',
                        transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0'
                    }}>

                    </div>
                    <div className="welcome-text">
                        <div className="sumdet">
                            <p style={{ color: 'white', fontWeight: 'bold', fontSize: '16px', marginBottom: '15px' }}>Hey friends,</p>
                            <h2 className="banner-text" style={{
                                fontSize: '45px',
                                fontWeight: '800',
                                lineHeight: '1.3em',
                                color: 'white'
                            }}>
                                Welcome to PT. Solusi Recovery Group (SRG) we are happy to help you, what can we do for you?
                            </h2>
                            <p style={{ marginTop: '20px', color: '#CFF0B4' }}>To Improve Financial cashflow, we are experts in both the bad credit (Collection & Recovery) and other financial sectors (F&B, Hospitality, any services, corporate, surveyor, legal etc)</p>
                        </div>
                        <div className="listdet">
                            <ul>
                                <li><a className='button' href="#" onClick={() => { toggleClass(".cModal", "show"); setState_modal('chemical') }}>SRG Binatu, Chemical & Plastic</a></li>
                                <li><a className='button' href="#" onClick={() => { toggleClass(".cModal", "show"); setState_modal('autocare') }}>SRG Autocare</a></li>
                                <li><a className='button' href="#" onClick={() => { toggleClass(".cModal", "show"); setState_modal('great_tasty') }}>SRG Great Tasty (F&B) </a></li>
                                <li><a className='button' href="#" onClick={() => { toggleClass(".cModal", "show"); setState_modal('hospitality') }}>SRG Hospitality</a></li>
                                {/* onClick={() => { toggleClass(".cModal", "show"); setState_modal('verification') }} */}
                                <li><a className='button' href="/silent_survey_and_validation_order" >SRG Silent Survey & Verification</a></li>
                                {/* onClick={() => { toggleClass(".cModal", "show"); setState_modal('recovery') }} */}
                                <li><a className='button' href="/collection_recovery">SRG Collection & Recovery</a></li>
                                {/* onClick={() => { toggleClass(".cModal", "show"); setState_modal('legal') }} */}
                                <li><a className='button' href="/legal_servicce" >SRG x Legal</a></li>
                                {/* onClick={() => { toggleClass(".cModal", "show"); setState_modal('cessie') }} */}
                                <li><a className='button' href="/cassie_servicce"  >SRG Cessie</a></li>
                            </ul>
                        </div>
                        <div style={{ marginTop: '45px' }}>
                            {/* <a href='#' className='button' style={{ padding: "10px 20px", fontSize: '16px', marginRight: "20px" }}>Contact</a> */}
                            <a href='#' onClick={() => { toggleClass(".welcome-text", "hide"); toggleClass(".listdet", "active") }} className='button' style={{ padding: "10px 20px", fontSize: '16px', border: "1px solid white" }}>See our services</a>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div style={{ marginBottom: "200px" }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="client-reviews">
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '630px' }}>
                                <h1 style={{ color: '#61CE70', fontWeight: "800", fontSize: "35px" }}>What peoples say about us</h1>
                                <p style={{ textAlign: 'center', marginTop: '15px' }}>Providing feedback about a business recovery company would depend on various factors such as its performance, service quality, client satisfaction, and overall reputation in the industry. Here are some key areas to consider when giving feedback</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "0px 10%" }}>
                        <Carousel responsive={responsive} showDots={false} arrows={false} autoPlay={true} infinite={true} autoPlaySpeed={5000}>
                            <Card clasname="r3">
                                <p>“You’ve read about the impoce of the being courageous, rebelliousi and it’s imaginative. These are all vital for ingredients in an effective advertising they must.”</p>
                                <CardProfile imgProfile={av1} nameProfile={"Patricia Muller"} jobProfile={"WordPress Developer"} />
                            </Card>
                            <Card clasname="r3">
                                <p>““You’ve read about the impoce of the being courageous, rebelliousi and it’s imaginative. These are all vital for ingredients in an effective advertising they must.””</p>
                                <CardProfile imgProfile={av2} nameProfile={"Michael Clark"} jobProfile={"Engeneer"} />
                            </Card>
                            <Card clasname="r3">
                                <p>“You’ve read about the impoce of the being courageous, rebelliousi and it’s imaginative. These are all vital for ingredients in an effective advertising they must.”</p>
                                <CardProfile imgProfile={av3} nameProfile={"Kent Milestone"} jobProfile={"Graphic Designer"} />
                            </Card>
                        </Carousel>
                    </div>
                </div>
            </section>
            <section>
                <div style={{ position: "relative", backgroundColor: "#102035", width: "100%", marginTop: "400px" }}>
                    <div style={{
                        backgroundImage: `url(${banner_footer})`, height: "457px", width: "80%", position: "absolute", top: "-250px", left: "0", right: "0", margin: "auto",
                        borderRadius: "20px", color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div style={{ width: "65%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <h1 style={{ marginBottom: "5px" }}>Your Problems are in the right hands</h1>
                            <p style={{ marginBottom: "25px" }}>the value of a business recovery company is a complex interplay of these factors, and a comprehensive evaluation considering all these aspects would be necessary to determine its true worth.</p>
                            <a href='#' className='button' style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px", fontSize: "16px", backgroundColor: "rgb(116, 86, 241)" }}>Reach out now</a>
                        </div>
                    </div>
                    <div style={{ height: "250px" }}>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
