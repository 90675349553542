import validation_order from '../assets/img/bg_validation_order.jpg'
function ValidationOrder() {
   
    return (
        <>
            <section>
            <img src={validation_order} style={{ marginRight: "10px", width: "100vw", objectFit: "contain" }} />
            </section>
        </>
    );
}

export default ValidationOrder;